var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',[_c('div',{staticClass:"headline primary--text"},[_vm._v("Permissões de colaboradores")])]),_c('v-card-text',[_c('v-row',{staticClass:"ma-1"},[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.cargoOptions,"item-text":"nome","item-value":"id","label":"Cargo","hide-details":"auto"},on:{"change":function($event){return _vm.atualizaPermissoes()}},model:{value:(_vm.cargoSelected),callback:function ($$v) {_vm.cargoSelected=$$v},expression:"cargoSelected"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.departamentoOptions,"item-text":"nome","item-value":"id","label":"Departamento","hide-details":"auto"},on:{"change":function($event){return _vm.atualizaPermissoes()}},model:{value:(_vm.departamentoSelected),callback:function ($$v) {_vm.departamentoSelected=$$v},expression:"departamentoSelected"}})],1)],1),_c('v-card',{staticClass:"ma-1 pa-3 my-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Permissões do colaborador - Módulo")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mb-1",attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.headers,"items":_vm.tools,"search":_vm.search,"loading":_vm.loading,"dense":"","fixed-header":"","height":"50vh","hide-default-footer":"","loading-text":"Carregando...","multi-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(" "+_vm._s(item.name.replace(/prospex/ig, _vm.getNomeProspex()).replace(/cda/ig, _vm.getNomeCDA()).replace(/reforços/ig, _vm.getNomeAulaExtraPlural()))+" ")]):_vm._e()]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [(item.group)?_c('span',[_vm._v(" "+_vm._s(item.group.replace(/prospex/ig, _vm.getNomeProspex()).replace(/cda/ig, _vm.getNomeCDA()).replace(/reforços/ig, _vm.getNomeAulaExtraPlural()))+" ")]):_vm._e()]}},{key:"item.allow_view",fn:function(ref){
var item = ref.item;
return [_c('v-data-table-checkbox',{on:{"on-click":function($event){return _vm.onChangeToolPermission(item)}},model:{value:(item.allow_view),callback:function ($$v) {_vm.$set(item, "allow_view", $$v)},expression:"item.allow_view"}})]}},{key:"item.allow_edit",fn:function(ref){
var item = ref.item;
return [_c('v-data-table-checkbox',{on:{"on-click":function($event){return _vm.onChangeToolPermission(item)}},model:{value:(item.allow_edit),callback:function ($$v) {_vm.$set(item, "allow_edit", $$v)},expression:"item.allow_edit"}})]}},{key:"item.allow_delete",fn:function(ref){
var item = ref.item;
return [_c('v-data-table-checkbox',{on:{"on-click":function($event){return _vm.onChangeToolPermission(item)}},model:{value:(item.allow_delete),callback:function ($$v) {_vm.$set(item, "allow_delete", $$v)},expression:"item.allow_delete"}})]}},{key:"item.allow_create",fn:function(ref){
var item = ref.item;
return [_c('v-data-table-checkbox',{on:{"on-click":function($event){return _vm.onChangeToolPermission(item)}},model:{value:(item.allow_create),callback:function ($$v) {_vm.$set(item, "allow_create", $$v)},expression:"item.allow_create"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card',{staticClass:"ma-1 pa-3 my-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Permissões do colaborador - Ações")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mb-1",attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.headersActions,"items":_vm.actions,"search":_vm.search,"loading":_vm.loading,"dense":"","fixed-header":"","height":"50vh","hide-default-footer":"","loading-text":"Carregando...","multi-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(_vm._s(_vm.getActionItemName(item)))]):_vm._e()]}},{key:"item.tool_name",fn:function(ref){
var item = ref.item;
return [(item.tool_name)?_c('span',[_vm._v(_vm._s(_vm.getActionItemToolName(item)))]):_vm._e()]}},{key:"item.allow",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"set":_vm.rowPermission = _vm.getActionPermission(item)}},[(_vm.rowPermission <= 3)?_c('v-radio-group',{staticStyle:{"margin-top":"0px","padding-top":"0px"},attrs:{"set":_vm.rowPermission = _vm.getActionPermission(item),"row":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.onChangeRadioGroup($event, item)}},model:{value:(_vm.rowPermission),callback:function ($$v) {_vm.rowPermission=$$v},expression:"rowPermission"}},[_c('v-radio',{attrs:{"label":"Desabilitada","value":"1"}}),_c('v-radio',{attrs:{"label":"Habilitada","value":"2"}}),(item.request_permission_implemented)?_c('v-radio',{attrs:{"label":"Habilitada perante autorização do Administrador","value":"3"}}):_vm._e()],1):(4)?_c('v-chip',{staticClass:"error",attrs:{"small":""}},[_vm._v(" Funcionalidade desabilitada, entre em contato com a Franchising ")]):_vm._e()],1)]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }