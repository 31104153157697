import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { departamentoStore, cargoStore, userToolStore, actionStore, toolStore } from '@/store';
import VDataTableCheckbox from '@/components/VDataTableCheckbox.vue';
import _ from 'lodash';
let Configuracao = class Configuracao extends Vue {
    constructor() {
        super(...arguments);
        this.entityObject = {};
        this.loading = false;
        this.search = '';
        this.headers = [
            { text: 'Módulo', value: 'name' },
            { text: 'Submódulo', value: 'group' },
            { text: 'Visualizar', value: 'allow_view' },
            { text: 'Editar', value: 'allow_edit' },
            { text: 'Deletar', value: 'allow_delete' },
            { text: 'Adicionar', value: 'allow_create' },
        ];
        this.headersActions = [
            { text: 'Função', value: 'name' },
            { text: 'Módulo', value: 'tool_name' },
            { text: 'Permissão', value: 'allow' },
        ];
        this.departamentoByCargo = {
            1: [1],
            2: [5, 4, 3, 2, 1],
            3: [4],
            4: [5],
            5: [4],
            6: [1, 2, 3, 4, 5] // Admnistrador
        };
        this.cargoSelected = null;
        this.departamentoSelected = null;
        this.actions = [];
        this.tools = [];
    }
    get departamentoOptions() {
        return _.filter(departamentoStore.departamentos, (item) => {
            if (!this.cargoSelected) {
                return false;
            }
            if (this.departamentoByCargo[this.cargoSelected].includes(item.id)) {
                return true;
            }
        });
    }
    get cargoOptions() {
        return cargoStore.cargos;
    }
    async atualizaPermissoes() {
        const optionsIds = _.map(this.departamentoOptions, (item) => item.id);
        if ((this.cargoSelected && this.departamentoSelected) && optionsIds.includes(this.departamentoSelected)) {
            this.loading = true;
            const payload = {
                cargo_id: this.cargoSelected,
                departamento_id: this.departamentoSelected
            };
            this.actions = await actionStore.getActionsDefault(payload);
            this.tools = await toolStore.getToolsDefault(payload);
            this.loading = false;
        }
    }
    getActionPermission(item) {
        if (item.allow) {
            return '2';
        }
        if (item.request_permission) {
            return '3';
        }
        return '1';
    }
    getActionItemName(item) {
        return (item.name
            // @ts-ignore
            .replace(/prospex/gi, this.getNomeProspex())
            // @ts-ignore
            .replace(/cda/gi, this.getNomeCDA())
            // @ts-ignore
            .replace(/reforço/gi, this.getNomeAulaExtraSingular()));
    }
    getActionItemToolName(item) {
        return (item.tool_name
            // @ts-ignore
            .replace(/prospex/gi, this.getNomeProspex())
            // @ts-ignore
            .replace(/cda/gi, this.getNomeCDA()));
    }
    async onChangeToolPermission(item) {
        await toolStore.updateToolsDefault(item);
    }
    async onChangeRadioGroup(evVal, item) {
        if (evVal === '1') {
            item.allow = false;
            item.request_permission = false;
            await actionStore.updateActionsDefault(item);
            return;
        }
        if (evVal === '2') {
            item.allow = true;
            item.request_permission = null;
            await actionStore.updateActionsDefault(item);
            return;
        }
        if (evVal === '3') {
            item.allow = false;
            item.request_permission = true;
            await actionStore.updateActionsDefault(item);
        }
    }
    async mounted() {
        this.loading = true;
        await userToolStore.getToolsSchool();
        await actionStore.getActions();
        await cargoStore.getCargos();
        this.loading = false;
    }
};
Configuracao = __decorate([
    Component({
        components: {
            VDataTableCheckbox,
        },
    })
], Configuracao);
export default Configuracao;
