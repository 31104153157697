import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
let VDataTableCheckbox = class VDataTableCheckbox extends Vue {
    async onChangeCidade(val) {
        this.$emit('input', val);
        this.$emit('on-click');
    }
};
__decorate([
    Prop()
], VDataTableCheckbox.prototype, "value", void 0);
__decorate([
    Watch('value')
], VDataTableCheckbox.prototype, "onChangeCidade", null);
VDataTableCheckbox = __decorate([
    Component
], VDataTableCheckbox);
export default VDataTableCheckbox;
